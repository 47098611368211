.loginPopupMain .closeModalLogin {
  background: red;
  border: 1px #182b49 solid;
  color: #fff;
  font-size: 16px;
  line-height: 28px;
  font-weight: bold;
  position: absolute;
  top: 12px;
  right: 12px;
  width: 30px;
  height: 30px;
  /* line-height: 30px; */
  /* font-size: 12px; */
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  z-index: 999;
  transition: all 0.15s ease-in-out;
}
.loginPopupMain .anchorLink {
  color: #182b49;
  font-weight: bold;
  font-size: 15px;
  text-decoration: underline;
  cursor: pointer;
}
.loginPopupMain .backButtonModal {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  text-align: center;
  background: yellow;
  border: 1px #000 solid;
  border-radius: 50%;
  cursor: pointer;
  z-index: 999;
  transition: all 0.15s ease-in-out;
}
.loginPopupMain svg {
  color: #000;
  font-size: 12px;
}

.loginPopupMain .UsernamesListings {
  background: #24c4ff;
  color: #fff;
}
.loginPopupMain .boxContent {
  text-align: center;
}
.loginPopupMain .boxContent .boxForStudent {
  /* background: #24c4ff; */
  color: #fff;
  text-align: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
}
.loginPopupMain .boxForStudent label {
  padding: 15px;
  font-size: 30px;
  cursor: pointer;
}
.loginPopupMain .LoginForm .form-group .btn {
  background: #11b67a;
  border: none;
  font-size: 15px;
  margin-bottom: 10px;
}
.loginPopupMain .LoginForm {
  /* background: #182b49; */
  position: relative;
  overflow: hidden;
  color: #000;
}
.loginPopupMain .LoginForm .error {
  font-size: 14px;
  line-height: 17px;
  font-weight: 1000;
  color: red;
  position: absolute;
  right: 20px;
  top: 0;
  /* padding: 0 8px; */
  /* background: #f3f3f3; */
  border-radius: 10px;
}
.loginPopupMain .modal-body .continue {
  background: #11b67a;
  border: none;
  width: 100%;
}

.loginPopupMain .htmlmodalerror {
  position: absolute;
  left: 0;
  text-align: center;
  width: calc(100%);
  top: 0px;
  color: #fff;
  background: #0f1a2d;
  padding: 10px;
  margin: 0;
  font-size: 15px;
}

.loginPopupMain .modal-footer button {
  border-radius: 0;
  border: none;
  padding: 15px;
  margin: 5px;
  background: #0f1a2d;
  color: #fff;
}
.loginPopupMain .modal-footer button:hover {
  border-radius: 0;
  /* border: none; */
  padding: 15px;
  background: #11b67a;
  color: #fff;
}
.loginPopupMain .modal-footer {
  padding: 0;
}
.loginRemembered {
  margin-top: 10px;
  /* border: 1px solid gainsboro; */
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}
.loginRemembered:hover {
  background: #eeeeee;
}
.loginRemembered .emailPassword {
  margin-left: 5px;
  position: relative;
  top: 8px;
}
.loginRemembered .emailPassword label {
  font-size: 14px;
  text-transform: lowercase;
} 
.loginRemembered .emailPassword span {
  position: relative;
  top: -6px;
}
.loginRemembered .circle {
  text-align: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 5px;
  color: #fff;
  font-weight: bold;
  border: 2px solid #fff;
}
.loginRemembered .circle span {
  position: relative;
  top: 3px;
  font-size: 20px;
  text-transform: uppercase;
}
@media screen and (max-width: 767px) {
  .loginPopupMain .modal-dialog {
    margin: -1px;
  }
}

.downloadAppPopupMain {
  background-color: #11b67a !important;
  color: #fff;
  border-radius: 10px !important;
}