.checkoutPopupMain {
  color: #fff;
}

.checkoutPopupMain input[type="checkbox"] {
  border: 2px solid #9eb3d1;
  appearance: none;
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-right: 6px;
  position: relative;
  top: 4px;
}

.checkoutPopupMain input[type="checkbox"]:checked {
  color: #182b49;
  background-color: #11b67a;
  background: #11b67a
  url("data:image/gif;base64,R0lGODlhCwAKAIABAP////3cnSH5BAEKAAEALAAAAAALAAoAAAIUjH+AC73WHIsw0UCjglraO20PNhYAOw==") 2px 2px no-repeat;
  border-color: #11b67a;
}

.checkoutPopupMain input[type="radio"] {
  border: 2px solid #9eb3d1;
  appearance: none;
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-right: 6px;
  position: relative;
  top: 4px;
}

.checkoutPopupMain input[type="radio"]:checked {
  color: #182b49;
  background-color: #11b67a;
  background: #11b67a
  url("data:image/gif;base64,R0lGODlhCwAKAIABAP////3cnSH5BAEKAAEALAAAAAALAAoAAAIUjH+AC73WHIsw0UCjglraO20PNhYAOw==") 2px 2px no-repeat;
  border-color: #11b67a;
}

.checkoutPopupMain hr {
  border-color: #eaeaea !important;
}

.checkoutPopupMain .modal-content {
  background: #f7f8f9;
  overflow: hidden;
  /* border: 3px solid #182B49; */
  z-index: 1;
}

.checkoutPopupMain .col-6 {
  color: #182b49;
}

.checkoutPopupMain .modal-footer button {
  background: #182b49;
  border: transparent;
  margin: auto;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 500;
  padding: 10px 15px;
}

.checkoutPopupMain .couponBoxDesign {
  padding: 7px;
  margin: 0;
  /* background: #182B49; */
  background: transparent;
  border: 1px #11b67a solid;
  border-radius: 6px;
}
.checkoutPopupMain .couponBoxDesign .form-control {
  border: none;
  outline: none;
}
.checkoutPopupMain .couponBoxDesign .form-control:focus {
  box-shadow: none;
}
.checkoutPopupMain hr {
  /* border: 1px; */
  background: #182b49;
}

.checkoutPopupMain .couponBoxDesign .col-md-9,
.col-xs-9 input {
  background: transparent;
  border-radius: 0;
  border: none;
  color: #182b49;
  border-bottom: 1px #182b49 solid;
  outline: none !important;
  box-shadow: none;
}

.checkoutPopupMain .couponBoxDesign button {
  background: #11b67a !important;
  border-color: #fff;
  color: #fff;
  font-size: 12px !important;
  position: relative;
  top: 0px;
  padding: 6px 0;
  border-radius: 5px;
}

.checkoutPopupMain .couponBoxDesign .col-md-2,
.col-xs-3 {
  padding: 0;
}

.checkoutPopupMain .form-group {
  margin: 0;
}
.checkoutPopupMain .couponBoxDesign .col-md-9,
.col-xs-9 {
  padding-left: 0;
}

.checkoutPopupMain .promoCodeButton {
  background: transparent !important;
  border: transparent;
  box-shadow: none;
  color: #182b49 !important;
  height: 50px;
  padding-left: 80px;
  position: relative;
  font-size: 18px;
  text-align: left;
}

.checkoutPopupMain .promoCodeButton i {
  background: #11b67a;
  padding: 17px;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  position: absolute;
  left: 20px;
  top: 0;
}
.checkoutPopupMain .manualPayment {
  margin-top: 4px;
  /* border: 1px #324e77 solid; */
  border-radius: 8px;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 2px 0 rgba(50, 50, 93, 0.06);
  border-radius: 4px;
}
.checkoutPopupMain .manualPayment h6 {
  color: #11b67a;
  margin-top: 40px;
}
.checkoutPopupMain .manualPayment ul {
  color: #182b49;
}
.checkoutPopupMain .nav-item a {
  border: 1px solid #ffffff !important;
}

.checkoutPopupMain .nav-link {
  /* border: 1px solid #ffffff !important; */
  width: 50%;
  text-align: center;
  padding: 0.9rem 3rem;
  margin-bottom: 0 !important;
  /* background-color: #cfd2d6; */
  color: #182b49 !important;
}
.checkoutPopupMain .nav-link.active {
  border: 1px solid #ffffff !important;
  width: 50%;
  text-align: center;
  padding: 0.9rem 3rem;
  background-color: #324e77 !important;
  color: #fff !important;
  border-radius: 5px;
  /* color: #11B67A !important; */
}
.checkoutPopupMain .nav-tabs {
  border: 1px solid #d4d7db !important;
  /* color: #11B67A !important; */
}
.checkoutPopupMain ul {
  padding: 35px !important;
  /* color: #11B67A !important; */
}

.checkoutPopupMain .step2 p {
  color: #182b49;
}
.checkoutPopupMain .step2 h5 {
  color: #182b49;
}
.checkoutPopupMain .step2 label {
  color: #182b49;
}
.checkoutPopupMain .step3 h5 {
  color: #182b49;
}
.checkoutPopupMain .step3 label {
  color: #182b49;
}
.checkoutPopupMain .step3 input[type="radio"] {
  border: 2px solid #11b67a;
  color: #182b49;
  appearance: none;
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-right: 6px;
  position: relative;
  top: 4px;
  border-radius: 50%;
}
.checkoutPopupMain .step3 input[type="radio"]:checked {
  color: #182b49;
  background-color: #11b67a;
  background: #11b67a
    url("data:image/gif;base64,R0lGODlhCwAKAIABAP////3cnSH5BAEKAAEALAAAAAALAAoAAAIUjH+AC73WHIsw0UCjglraO20PNhYAOw==")
    2px 2px no-repeat;
  border-color: #11b67a;
}
.checkoutPopupMain .checkoutBox {
  display: inline-block;
  min-height: 50px;
  line-height: 50px;
  border-radius: 5px;
  margin: 10px 0;
  width: 100%;
  background: #11b67a;
  border: 3px transparent solid;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.checkoutPopupMain .checkoutBox :hover {
  background: #fff;
  color: #182b49;
}

.checkoutPopupMain .checkoutBox p {
  margin: 0;
  font-weight: 600;
  font-size: 0.9rem;
  padding-left: 15px;
  padding-right: 85px;
  color: #fff;
  position: relative;
  line-height: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.checkoutPopupMain .checkoutBox p::after {
  content: "Select";
  width: 70px;
  height: 35px;
  position: absolute;
  z-index: 999;
  color: #182b49;
  background: #fff;
  border: 1px #02bb7c solid;
  top: calc(50% - 17px);
  right: 7px;
  border-radius: 7px;
  font-size: 12px;
  text-transform: none;
  text-align: center;
  line-height: 35px;
}
.checkoutPopupMain .teacherSection {
  padding: 5vh 0px;
}
.checkoutPopupMain .teacherSection span {
  margin-top: 20px;
  font-weight: 600;
  color: #182b49;
  font-size: 16px;
}
.checkoutPopupMain .teacherSection label {
  font-weight: 600;
  color: #02bb7c;
}
.checkoutPopupMain .teacherSection img.tutorImg {
  margin-bottom: 10px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
/* .checkoutPopupMain .nav {
  display: inline-block;
  border-radius: 5px;
  box-shadow: 0 8px 25px rgba(0,0,0,0.1);
  margin-bottom: 35px;
}
.checkoutPopupMain .nav .nav-item {
  display: inline-block;

}
.checkoutPopupMain .nav .nav-item .nav-link {
  font-weight: 500;
  text-transform : uppercase;
  padding: 12px 80px 10px;
  border-radius: 5px;
}

@media screen and (max-width: 767px) {
  .checkoutPopupMain .nav .nav-item .nav-link {
    padding: 12px 36px 9px;
  }
} */

@media screen and (max-width: 767px) {
  .checkoutPopupMain .nav-link {
    font-size: 11px;
  }
}
